import { useState, useEffect, useRef } from 'react';
import {
  Dialog,
  Button,
  ThemeProvider,
  createTheme,
  Switch,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  styled,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styles from './ConsentDialog.module.scss';
import { useSelector } from 'react-redux';
import { categotiesData, consentCookieName, countryWithRegions, privacyRegions, regionSpecificCategories } from './consent-configs';
import TagManager from 'react-gtm-module';
import { useRouter } from 'next/router';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';

const theme = createTheme({
  palette: {
    primary: {
      main: '#00454A',
    },
    secondary: {
      main: '#0a0a0a',
    },
  },
  typography: {
    fontFamily: '"Libre Franklin", sans-serif',
    color: '#0a0a0a',
  },
});

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#3C6562',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.grey[100],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.7,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: '#E9E9EA',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const defaultConsent = {
  analytics: false,
  marketing: false,
  personalization: false,
  data_sharing: false, // "Do Not Sell My Data" for CCPA, CPRA, etc.
};

const ConsentDialog = () => {
  const [consentDialogOpen, setConsentDialogOpen] = useState(false);
  const [consentBannerShown, setConsentBannerShown] = useState(true);
  const [applicableCategories, setApplicableCategories] = useState([]);
  const [consent, setConsent] = useState(defaultConsent);
  const [isConsentConfirmed, setIsConsentConfirmed] = useState(false);
  const [isConsentReviewMode, setIsConsentReviewMode] = useState(false);
  const router = useRouter();

  const userStore = useSelector((state) => state.user.value);
  const isFirstRender = useRef(true); // Track initial render

  const isCountryWithRegions = (countryCode) => {
    return countryWithRegions.includes(countryCode)
  }

  const getRegionCategories = (regionCode) => {
    const regulation = Object.keys(privacyRegions).find((key) =>
      privacyRegions[key].includes(regionCode)
    ) || 'default';

    console.log('Consent regulation:', regulation);
    return regionSpecificCategories[regulation] || regionSpecificCategories.default;
  };

  const initializeConsent = (categories) =>
    categories.reduce((acc, category) => {
      acc[category] = false;
      return acc;
    }, {});

  const toggleConsent = (type) => {
    setConsent((prev) => ({ ...prev, [type]: !prev[type] }));
  };

  useEffect(() => {
    const cookie = document.cookie.split('; ').find((row) => row.startsWith(consentCookieName));
    if (cookie) {
      const consentFromCookie = JSON.parse(cookie.split('=')[1]);
      console.log('consentFromCookie:', consentFromCookie);
      setConsent(consentFromCookie);
      setConsentBannerShown(false);
    } else if (!consentDialogOpen && userStore?.detectCountry) {
      const applicableCategoriesList = getRegionCategories(userStore.detectCountry);
      setApplicableCategories(['general', 'functional', ...applicableCategoriesList]);
      setConsent(initializeConsent(applicableCategoriesList));
      console.log('applicableCategoriesList:', applicableCategoriesList);
      setConsentBannerShown(true);
    }
  }, [userStore?.detectCountry, consentDialogOpen]);

  useEffect(() => {
    if (router.query['cookieConsent']) {
      setIsConsentReviewMode(true);
      setConsentBannerShown(true);
    }
  }, [router])

  const isConsentRequired = (category) => {
    return applicableCategories.includes(category);
  }

  const closeConsentDialog = () => {
    setConsentDialogOpen(false);
    setConsentBannerShown(false);
    setIsConsentReviewMode(false);
    if (router.query['cookieConsent']) {
      const routerQuery = { ...router.query };
      delete routerQuery['cookieConsent'];
      router.push({
        pathname: router.pathname,
        query: {...routerQuery},
      }, undefined, {
        shallow: true
      });
    }

    setIsConsentConfirmed(false);
  };

  const updateTagManagerConsent = () => {
    const consentPersonalization = isConsentRequired('personalization')
    ? (consent.personalization ? 'granted' : 'denied')
    : 'granted';

    const consentData = {
      analytics_storage: isConsentRequired('analytics')
        ? (consent.analytics ? 'granted' : 'denied')
        : 'granted',
      ad_storage: isConsentRequired('marketing') 
        ? (consent.marketing ? 'granted' : 'denied')
        : 'granted',
      ad_user_data: consentPersonalization,
      ad_personalization: consentPersonalization,
      personalization_storage: consentPersonalization,
      functionality_storage: 'granted',
      security_storage: 'granted',
      data_sharing: isConsentRequired('data_sharing')
        ? (consent.data_sharing ? 'granted' : 'denied')
        : 'granted', // Default to granted if no consent is required
    };

    console.log('Updating consent with data:', consentData);
    
    const cookieDate = new Date(Date.now() + 3600 * 24 * 365 * 1000);
    document.cookie = `BC-Consent-Cookie=${JSON.stringify(consentData)};path=/;expires=${cookieDate.toUTCString()};`;
    localStorage.setItem('BC-Consent-Cookie', JSON.stringify(consentData));

    TagManager.dataLayer({
      dataLayer: {
        event: 'gtm_consent_update', //'update_consent_preferences',
        consent_preferences: consentData,
      },
    });

    //   ad_storage: false,  // Sets consent for storing and accessing user data related to advertising.
    //   analytics_storage: false,  // Sets consent for storing and accessing user data related to analytics.
    //   ad_user_data: false,  // Sets consent for sending user data related to advertising to Google.
    //   ad_personalization: false,  // Sets consent for personalized advertising.
    //   functionality_storage: false, // Enables storage that supports the functionality of the website or app such as language settings.
    //   personalization_storage: false, // Enables storage that supports the personalization of the website or app.
    //   security_storage: false, // Enables storage related to security such as authentication functionality, fraud prevention, and other user protection
    //   data_sharing: false, // "Do Not Sell My Data" for CCPA, CPRA, etc.

  };

  const handleConsentSave = () => {
    setConsentDialogOpen(false);
    setConsentBannerShown(false);
    localStorage.setItem(consentCookieName, JSON.stringify(consent));
    document.cookie = `${consentCookieName}=${JSON.stringify(
      consent
    )};path=/;max-age=${3600 * 24 * 365};`;

    updateTagManagerConsent();
    localStorage.setItem('bcCookieConsentShowed', true);
    window.dispatchEvent(new Event('storage'));

    if (router.query['cookieConsent']) {
      const routerQuery = { ...router.query };
      delete routerQuery['cookieConsent'];
      router.push({
        pathname: router.pathname,
        query: {...routerQuery},
      }, undefined, {
        shallow: true
      });
    }

    setIsConsentConfirmed(false);
  };

  useEffect(() => {
    const userCountry = userStore?.detectCountry;
    const userRegion = isCountryWithRegions(userCountry) ? `-${userStore?.detectRegion}` : '';
    const regionCode = userCountry + userRegion;
    console.log('Region Code: ', regionCode);
    const categories = getRegionCategories(regionCode);
    setApplicableCategories(['general', 'functional', ...categories]);
    console.log('Consent categories: ', categories);
    setConsent(initializeConsent(categories));
  }, [userStore?.detectCountry]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false; // Mark the first render as complete
      return; // Skip the effect on the first render
    }

    if ((isConsentConfirmed) && (consentBannerShown || consentDialogOpen)) {
      console.log('Consent changed:', consent);
      setConsentDialogOpen(false);
      setConsentBannerShown(false);
      handleConsentSave();
    }
  }, [consent, applicableCategories, isConsentConfirmed]);

  return (
    <>
      {
        consentBannerShown && (
          <ThemeProvider theme={theme}>
            <div className={styles.consentBanner}>
              <div className='container'>
                {isConsentReviewMode && (
                  <IconButton
                    onClick={() => closeConsentDialog()}
                    aria-label="Close"
                    className='absolute top-2 right-2'
                  >
                    <CloseIcon />
                  </IconButton>
                )}
                <div className='grid grid-cols-12 gap-x-4 w-full'>
                  <div className='lg:col-span-7 col-span-12 mb-3'>
                    <h2 className='font-libre text-2xl text-left'>Our website uses cookies</h2>
                      <p> We use technical, analytical and marketing cookies to personalise your experience and to give you the most relevant results</p>
                  </div>
      
                  <div className='flex justify-between items-center lg:col-span-5 col-span-12'>
                    <Button
                      color='secondary'
                      type='button'
                      variant="outlined"
                      onClick={() => {
                        setConsent(initializeConsent(applicableCategories));
                        setIsConsentConfirmed(true);
                      }}>
                      Reject all
                    </Button>
                    <Button
                      color="secondary"
                      type='button'
                      variant="outlined"
                      onClick={() => {
                        setConsentDialogOpen(true);
                        setConsentBannerShown(false);
                      }}
                    >
                      Customize
                    </Button>
      
                    <Button 
                      color="secondary"
                      type='button'
                      variant="contained"
                      sx={{
                        backgroundColor: '#3C6562',
                        '&:hover': {
                          backgroundColor: '#00454A',
                        }
                      }}
                      onClick={() => {
                        setConsent(applicableCategories.reduce((acc, category) => {
                          acc[category] = true;
                          return acc;
                        }, {}));
                        setIsConsentConfirmed(true);
                      }}
                    >Accept all</Button>
                  </div>
                </div>
              </div>
            </div>
          </ThemeProvider>
        )
      }
    
      <Dialog
        open={consentDialogOpen}
        disableEscapeKeyDown
        PaperProps={{ style: { borderRadius: 0 } }}
        maxWidth="md"
        aria-label="Manage your privacy preferences"
        className={styles.consentDialog}
      >
        <div className="font-libre p-5">
          <ThemeProvider theme={theme}>
            <div className="flex items-center w-full">
              <h3 className="font-libre my-3 text-base lg:text-2xl">
                Manage your privacy preferences
              </h3>
              {isConsentReviewMode && (
                <IconButton
                  onClick={() => closeConsentDialog()}
                  aria-label="Close"
                  className='ml-auto'
                >
                  <CloseIcon />
                </IconButton>
              )}
            </div>

            <p>
              We use cookies to help you navigate efficiently and perform
              certain functions. We also use third-party cookies that help us analyze and understand how you use this site. These cookies will be stored
              in your browser only with your consent.
            </p>
            <p className="mb-8">
              You can choose to enable or disable some or all of these cookies
              but disabling some of them may affect your browsing experience.{' '}
              You can read more about each type in our{' '}
              <a href="/view/privacy_policy" target="_blank" rel="noopener noreferrer" className="underline text-black">Privacy Policy</a>.
            </p>
            <div className='mb-4'>

              {applicableCategories.map((category) => (
                <Accordion key={category}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <div className='flex items-center w-full'>
                      <Typography variant="h6">{categotiesData[category].title}</Typography>
                      {categotiesData[category].required && (
                        <Typography variant="body2" style={{ marginLeft: 'auto', color: '#3C6562' }}>Always Active</Typography>
                      )}
                      <IOSSwitch 
                        checked={consent[category] || categotiesData[category].required}
                        onChange={() => toggleConsent(category)}
                        name={category}
                        disabled={categotiesData[category].required}
                        className='ml-auto mr-3'
                        onClick={(event) => event.stopPropagation()}
                      />
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      {categotiesData[category].description}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
            <div className='flex justify-between flex-wrap lg:flex-nowrap'>
              <Button
                color='secondary'
                type='button'
                variant="outlined"
                className='w-full lg:mr-2 mb-2'
                onClick={() => {
                  setConsent(initializeConsent(applicableCategories));
                  setIsConsentConfirmed(true);
                }}>
                Reject all
              </Button>
              <Button
                color="secondary"
                type='button'
                variant="contained"
                className='w-full lg:mr-2 mb-2'
                sx={{
                  backgroundColor: '#3C6562',
                  '&:hover': {
                    backgroundColor: '#00454A',
                  }
                }}
                onClick={() => {
                  setIsConsentConfirmed(true);
                  handleConsentSave();
                }}
              >
                Save Preferences
              </Button>

              <Button 
                color="secondary"
                type='button'
                variant="contained"
                className='w-full mb-2'
                sx={{
                  backgroundColor: '#3C6562',
                  '&:hover': {
                    backgroundColor: '#00454A',
                  }
                }}
                onClick={() => {
                  setConsent(applicableCategories.reduce((acc, category) => {
                    acc[category] = true;
                    return acc;
                  }, {}));
                  setIsConsentConfirmed(true);
                }}
              >Accept all</Button>
            </div>
          </ThemeProvider>
        </div>
      </Dialog>
    </>
  );
};

export default ConsentDialog;
