import {
  GoogleMap,
  OverlayView,
  Polyline,
  useJsApiLoader,
} from '@react-google-maps/api';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import styles from './Map.module.scss';
import CurrencyFormat from 'react-currency-format';
import getSymbolFromCurrency from 'currency-symbol-map';
import { Box } from '@mui/material';
import { OfferCard } from '../OfferCard/OfferCard';

const mapOptions = {
  styles: [
    {
      featureType: 'poi',
      stylers: [{ visibility: 'off' }],
    },
    {
      featureType: 'road',
      stylers: [{ visibility: 'off' }],
    },
    {
      featureType: 'transit.station.airport',
        stylers: [
          {
            "color": "#00454A"
          },
          {
            "visibility": "on"
          }
        ]
    },
    {
      "featureType": "transit.station.airport",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "color": "#D4A976"
        }
      ]
    },
    {
      "featureType": "transit.station.airport",
      "elementType": "labels.text",
      "stylers": [
        {
          "color": "#001724"
        },
        {
          "visibility": "simplified"
        }
      ]
    },
    {
      featureType: 'landscape',
      stylers: [{ color: '#f5f5f5' }],
    },
    {
      featureType: 'water',
      stylers: [{ color: '#d9edf7' }],
    },
  ],
  disableDefaultUI: false, // Keep default controls for navigation
};

const OffersMap = ({ center, containerStyle, offers, offerFrom }) => {
  const userStore = useSelector((state) => state.user.value);
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyBL8Lalt1Ix2CO9Ptq-doZ6l1i_csmepvo',
  });
  const onOfferClick = (offer) => setActiveOffer(offer);
  // eslint-disable-next-line no-unused-vars
  const [map, setMap] = useState(null);
  const [activeOffer, setOffer] = useState(null);
  const [zoomLevel, setZoomLevel] = useState(3); // Default zoom level
  let timeout;
  const setActiveOffer = (offer) => {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      setOffer(offer);
    }, 500);
  };
  useEffect(() => {
    if (isLoaded && !!map) {
      setTimeout(() => {
        map.setCenter(center);
      }, 500);
    }
  }, [isLoaded, map]);

  const onLoad = useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds();
    setTimeout(() => {
      map.setZoom(2.5);
      setMap(map);
      setZoomLevel(map.getZoom());
      const transitLayer = new window.google.maps.TransitLayer();
      transitLayer.setMap(map);
    }, 500);
    map.fitBounds(bounds);
    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback() {
    setMap(null);
  }, []);

  const handleZoomChanged = () => {
    if (map) {
      setZoomLevel(map.getZoom());
    }
  };

  return isLoaded ? (
    <div className={styles.mapContainer}>
      <GoogleMap
        mapContainerStyle={{...containerStyle}}
        options={mapOptions}
        zoom={3}
        onLoad={onLoad}
        onUnmount={onUnmount}
        onZoomChanged={handleZoomChanged}
      >
        {!!offers?.length &&
          offers.map((offer, index) => {
            const isDetailedView = zoomLevel >= 5; // Adjust zoom threshold as needed

            return (
              <React.Fragment key={offer.code + index}>
                {/* OverlayView for custom marker */}
                <OverlayView
                  position={{
                    lat: offer.latitude,
                    lng: offer.longitude,
                  }}
                  mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                >
                  <div
                    className={cn(styles.customMarker, {
                      [styles.activeMarker]:
                        activeOffer && offer.code === activeOffer.code,
                      [styles.currentMarker]:
                        offerFrom && offer.code === offerFrom.code,
                    })}
                    onMouseEnter={() => {
                      if (offer.code === offerFrom.code) {
                        return;
                      }
                      setActiveOffer(offer);
                    }}
                    onClick={() => {
                      if (offer.code === offerFrom.code) {
                        return;
                      }
                      onOfferClick(offer);
                    }}
                    onMouseLeave={() => setActiveOffer(null)}
                  >
                    <div>
                      {offer.code === offerFrom.code && 'From ' + offer.name}
                    </div>
                    {offer.code !== offerFrom.code && (
                      <>
                        {isDetailedView &&
                          offer.code !== offerFrom.code &&
                          offer.name}
                        <div className={styles.priceWrapper}>
                          {isDetailedView && <span>From </span>}

                          <CurrencyFormat
                            thousandSeparator
                            displayType={'text'}
                            prefix={`${getSymbolFromCurrency(
                              userStore.currency
                            )}`}
                            value={Math.round(offer.offer.price)}
                          />
                        </div>
                      </>
                    )}

                    <div
                      className={cn(styles.popup, 'text-left w-auto', {
                        [styles.popupActive]:
                          activeOffer && offer.code === activeOffer.code,
                      })}
                      onMouseEnter={() => {
                        setActiveOffer(offer);
                      }}
                    >
                      <OfferCard data={offer.offer}
                        key={offer.code}
                        searchSource={'Offers map'}
                        closeDetails={() => {
                          setActiveOffer(null);
                          sessionStorage.setItem('BC-offerIDOpened', null);
                        }}></OfferCard>
                    </div>
                  </div>
                </OverlayView>
                {/* Geodesic line */}
                <Polyline
                  path={[
                    {
                      lat: offerFrom.latitude,
                      lng: offerFrom.longitude,
                    },
                    {
                      lat: offer.latitude,
                      lng: offer.longitude,
                    },
                  ]}
                  options={{
                    geodesic: true,
                    strokeColor: '#00454A',
                    strokeOpacity: 0.6,
                    strokeWeight: 1,
                  }}
                />
              </React.Fragment>
            );
          })}
      </GoogleMap>
    </div>
  ) : (
    <Box
      sx={{
        height: '810px',
        backgroundColor: '#131313',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    ></Box>
  );
};

export default memo(OffersMap);
