import cn from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { httpGet } from '../../utils/httpClient';
import styles from './OffersViews.module.scss';
import cloneDeep from 'lodash/cloneDeep';
import { VintageButton } from '../index';
import { Form, Formik } from 'formik';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import CloseIcon from '@mui/icons-material/Close';

import { useWindowWidth } from '@react-hook/window-size';
import TuneRoundedIcon from '@mui/icons-material/TuneRounded';
import qs from 'qs';
import {
  Box,
  Button,
  Dialog,
  FormControl,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Skeleton,
  ThemeProvider,
  Tooltip,
  createTheme,
  debounce,
} from '@material-ui/core';
import { usePrevious } from '../../utils/usePrevious';
import PropTypes from 'prop-types';
import { OfferCard } from '../OfferCard/OfferCard';
import { CABIN_CLASSES } from '../../utils/cabinClasses';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import { BCGlobe } from '../Globe/Globe';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useSelector } from 'react-redux';
import OffersMap from '../Map/OffersMap';
import { styled } from '@mui/material/styles';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup, {
  toggleButtonGroupClasses,
} from '@mui/material/ToggleButtonGroup';
import {
  getOfferDestinationsByOrigin,
  getOfferOriginsAndDestinationsByUserLocation,
} from '../../utils/remoteAPI/offer';
// import { AirlineAutocomplete } from '../AirlineAutocomplete/AirlineAutocomplete';
import { COUNTRIES } from '../../config';
import TagManager from 'react-gtm-module';

const theme = createTheme({
  shape: {
    borderRadius: 12,
  },
  palette: {
    primary: {
      main: '#00454A',
    },
    dark: {
      main: '#0a0a0a',
    },
  },
  typography: {
    fontFamily: '"Libre Franklin", sans-serif',
  },
  components: {
    MuiToggleButtonGroup: {
      styleOverrides: {
        grouped: {
          margin: "4px",
          borderRadius: "10px",
          "&:not(:last-of-type)": {
            borderTopRightRadius: '10px',
            borderBottomRightRadius: '10px',
          },
          "&:not(:first-of-type)": {
            marginLeft: '4px',
            borderLeft: 1,
            borderLeftStyle: 'solid',
            borderLeftColor: '#20202033',
            borderTopLeftRadius: 10,
            borderBottomLeftRadius: 10
          },
          '&.Mui-selected': {
            color: '#fff',
            backgroundColor: '#BB9269',
            borderColor: '#BB9269',
            '&:hover': {
              backgroundColor: '#BB9269',
            },
          },
        },
      },
    },
  },
});

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '&.MuiToggleButtonGroup-root': {
    overflow: 'hidden',
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
  },
  [`& .${toggleButtonGroupClasses.grouped}`]: {
    margin: theme.spacing(0.5),
    border: 0,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#fff',
    color: '#00454a',
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(16),
    height: 48,
    position: 'relative',
    bottom: '-1px',
    transform: 'skew(30deg)',
    left: '-17px',
    '&.Mui-selected': {
      color: '#fff',
      backgroundColor: '#BB9269',
      '&:hover': {
        backgroundColor: '#BB9269',
      },
    },
    '&.Mui-focusVisible': {
      backgroundColor: '#fff',
    },
    '&.MuiButtonBase-root': {
      padding: 16,
      height: 48,
      maxHeight: 48,
      minHeight: 48,
    },
    [`&.${toggleButtonGroupClasses.disabled}`]: {
      border: 0,
    },
  },
  [`& .${toggleButtonGroupClasses.grouped}:first-of-type`]: {
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
  },
}));

const getOffers = async (page, perPage, loadingHandler, filters = {}) => {
  loadingHandler(true);
  const query = { ...filters };

  if (filters.airline) {
    query.airline = filters.airline.code;
  }

  if (filters.sort) {
    query.sort = filters.sort;
  }

  const url = `/api/offer/items?page=${page}&size=${perPage}` + (Object.keys(query).length ? `&${qs.stringify(query)}` : '');

  return await (await httpGet(url, true)).json();
};

const sortOptions = [
  { value: 'modifiedDate', label: 'Default' },
  { value: 'price,asc', label: 'Low price' },
  { value: 'price,desc', label: 'High price' },
  { value: 'discount,desc', label: 'High discount' },
  { value: 'cityDestination.name,asc', label: 'Destination A - Z' },
  { value: 'cityDestination.name,desc', label: 'Destination Z - A' },
];

OffersViews.propTypes = {
  heading: PropTypes.string,
};

export function OffersViews({ defaultOrigin }) {
  const defaultFilters = {
    airline: '',
    origin: defaultOrigin.origin || 'All origins',
    destination: '',
    region: 'All',
    cabinClass: 'BIZ',
    sort: 'modifiedDate',
  };
  const [currentPage, setCurrentPage] = useState(0);
  const [offers, setOffers] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState(defaultFilters);
  const [mobileFilters, setMobileFilters] = useState(false);
  const [cabinClasses, setCabinClasses] = useState([]);
  const [view, setView] = React.useState('card');
  const globeContainerRef = useRef();
  const [globeWidth, setGlobeWidth] = useState(0);
  const [globeHeight, setGlobeHeight] = useState(0);
  const [places, setPlaces] = useState(null);
  const width = useWindowWidth();
  useEffect(() => {
    setGlobeWidth(globeContainerRef.current.offsetWidth);
    setGlobeHeight(
      globeContainerRef.current.offsetWidth / (width > 1024 ? 2 : 1.5) + 200
    );
  }, [globeContainerRef, width]);
  const [globeAltitude, setGlobeAltitude] = useState(1.7);
  const [globeCurrentAltitude, setGlobeCurrentAltitude] = useState(null);
  const [offerCities, setOfferCities] = useState(null);
  const [regions, setRegions] = useState(null);
  const [activeRegion, setActiveRegion] = useState('All');
  const [activeOrigin, setActiveOrigin] = useState(defaultOrigin.origin);
  const perPage = 9;
  const prevFilters = usePrevious({ filters });

  const userStore = useSelector((state) => state.user.value);

  useEffect(() => {
    const cabinClasses = [...CABIN_CLASSES].reverse();
    cabinClasses.pop();
    setCabinClasses(cabinClasses);
  }, []);

  useEffect(() => {
    const fetchCities = async () => {
        try {
          const result = await getOfferOriginsAndDestinationsByUserLocation(
            defaultOrigin.origin === 'All origins' || !defaultOrigin.origin || (defaultOrigin.country !== userStore?.detectCountry) ? null : defaultOrigin.origin,
            defaultOrigin.country
          );

          setOfferCities(result);
          getRegions(result.destinations);

          if (!result.closestAirport || (defaultOrigin.country !== userStore?.detectCountry || defaultOrigin.origin !== activeOrigin)) {
            setActiveOrigin('All origins');
            defaultFilters.origin = 'All origins';
            setFilters({resetFilters: true, origin: 'All origins'});
          }
         
          if (result.closestAirport) {
            setActiveOrigin(result.closestAirport.code);
            setFilters({...filters, origin: result.closestAirport.code});
          }
          getAllOffers();

        } catch (err) {
          console.error(err);
        }
    };
    fetchCities();

  }, [defaultOrigin.origin, defaultOrigin.country]);

  const getRegions = (destinations) => {
    const regionsMap = new Map();
    destinations.forEach(({ regionId, regionName }) => {
      if (!regionsMap.has(regionId)) {
        if (regionId === null) {
          regionsMap.set(0, { id: 0, name: 'Not specified' });
          return;
        };
        regionsMap.set(regionId, { id: regionId, name: regionName });
      }
    });
    
    const regionsArray = Array.from(regionsMap.values());
    setRegions(regionsArray);
  }

  const renderFilters = ({ setFieldValue, values }) => {
    return (
      <>
        {offerCities &&
          offerCities.origins?.length > 0 && (
            <>

                <div className={cn(styles.filterSmaller)}>
                  <Select
                    value={values.origin || activeOrigin}
                    onChange={(event) => {
                      setFieldValue('origin', event.target.value);
                    }}
                    fullWidth
                    defaultValue={ activeOrigin || 'All origins'}
                    name={'origin'}
                    className={(view === 'map' || view === 'globe') && filters.origin === 'All origins' ? 'bg-red-200' : 'bg-white'}
                  >
                    <MenuItem value={'All origins'}>- All origins -</MenuItem>
                    {offerCities.origins?.map((origin) => (
                      <MenuItem
                        key={origin.code + origin.name}
                        value={origin.code}
                      >
                        {origin.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {(view === 'map' || view === 'globe') && filters.origin === 'All origins' && (
                  <span className="block text-xs leading-[0.7] text-red-400">Please select an origin to show destinations for it on the map</span>
                )}
                </div>

              {offerCities.destinations?.length > 6 && (
                <div className={cn(styles.filterSmaller)}>
                  <Select
                    value={values.destination || 'All destinations'}
                    onChange={(event) => {
                      setFieldValue('destination', event.target.value);
                    }}
                    fullWidth
                    defaultValue={'All destinations'}
                    name={'destination'}
                    className='bg-white'
                  >
                    <MenuItem value={'All destinations'}>
                      - All destinations -
                    </MenuItem>
                    {offerCities.destinations?.map((destination) => (
                      <MenuItem
                        key={destination.code + destination.name}
                        value={destination.code}
                      >
                        {destination.name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              )}
            </>
          )}

        {/* <div className={cn(styles.filter)}>
            <FlightSearchAutocomplete
                setFieldValue={setFieldValue}
                name="origin"
                label="Origin"
                showLabel={false}
                icon={<FlightTakeoffIcon />}
                // error={!!(touched.origin && errors.origin)}
                patch={{ value: values.origin }}
            />
        </div>

        <div className={cn(styles.filter)}>
            <FlightSearchAutocomplete
                setFieldValue={setFieldValue}
                name="destination"
                label="Destination"
                showLabel={false}
                icon={<FlightLandIcon />}
                // error={!!(touched.destination && errors.destination)}
                patch={{ value: values.destination }}
            />
        </div> */}

        <div className={cn(styles.filterSmaller)}>
          {cabinClasses && (
            <Select
              value={values.cabinClass}
              onChange={(event) => {
                setFieldValue('cabinClass', event.target.value);
              }}
              fullWidth
              defaultValue={'Any class'}
              name={'cabinClass'}
              className='bg-white'
            >
              <MenuItem value={'Any class'}>- Any class -</MenuItem>
              {cabinClasses.map((cabin) => (
                <MenuItem key={cabin.value} value={cabin.value}>
                  {cabin.label}
                </MenuItem>
              ))}
            </Select>
          )}
        </div>

        {/* <div className={styles.filterSmaller}>
          <AirlineAutocomplete
            name="airline"
            fullWidth
            value={values.airline}
            placeholder="Airline"
            primaryColor="#202020"
            setFieldValue={setFieldValue}
            className='bg-white'
          />
        </div> */}
      </>
    );
  };

  const validateFilters = (f) => {
    const filters = cloneDeep(f);
    filters.country = userStore?.country;

    // const keysToRemove = Object.keys(filters).filter((key) => {
    //   const value = filters[key];
    //   return (
    //     value === 'All origins' ||
    //     value === 'All destinations' ||
    //     value === 'Any class' ||
    //     value === 'All countries' ||
    //     value === 0 ||
    //     value === '' ||
    //     value === null ||
    //     value === undefined
    //   );
    // });

    //   keysToRemove.forEach((key) => delete filters[key]);

    if (filters.resetFilters) {
      delete filters.resetFilters;
    }

    if (!filters.destination || filters?.destination === 'All destinations') {
      delete filters.destination;
    }

    if (!filters.airline) {
      delete filters.airline;
    }

    if (filters.region === 0 || !filters.region || filters.region === 'All') {
      delete filters.region;
    }

    if (filters?.origin === 'All origins') {
      delete filters.origin;
    }

    if (offerCities?.origins?.length && offerCities?.origins.find((o) => o.code === filters.origin) === undefined) {
      delete filters.origin;
    }

    if (!filters?.country || filters?.country === 'All countries') {
      delete filters.country;
    }

    if (!filters.cabinClass || filters.cabinClass === 'Any class') {
      delete filters.cabinClass;
    }

    if (filters.sort && !Object.keys(filters.sort).length) {
      delete filters.sort;
    }
    return filters;
  };

  const sendGlobeEventToTagManager = (eventType) => {
    const tagManagerArgs = {
      dataLayer: {
        event: 'globeUserAction',
        userCountry: userStore?.country.toUpperCase(),
        eventType,
      },
    };
    TagManager.dataLayer(tagManagerArgs);
  };

  function createGlobePlaces(offers) {
    if (!offers || !offers.length) {
      return [];
    }

    const homeCity = offers[0].origin;
    console.log('homeCity', homeCity);
    const places = [
      {
        name: homeCity.name,
        code: homeCity.code,
        latitude: homeCity.latitude,
        longitude: homeCity.longitude,
        offer: offers[0],
      },
    ];

    offers.forEach((offer) => {
      if (
        offer.origin.code === homeCity.code &&
        offer.destination.latitude &&
        offer.destination.longitude &&
        offer.destination.code
      ) {
        places.push({
          name: offer.destination.name,
          code: offer.destination.code,
          latitude: offer.destination.latitude,
          longitude: offer.destination.longitude,
          offer,
        });
      }

      if (!offer.origin.latitude || !offer.origin.longitude || !offer.origin.code || !offer.destination.code
        || !offer.destination.latitude || !offer.destination.longitude
      ) {
        console.log('Offer without required data:', offer);
      }
    });

    return places;
  }

  const handleViewChange = (event, nextView) => {
    setView(nextView);
  };

  useEffect(() => {
    if (JSON.stringify(defaultFilters) !== JSON.stringify(filters)) {
      const f = validateFilters(filters);
      getOffers(currentPage, perPage, setLoading, f).then((res) => {
        setHasMore(!res.last);
        setOffers([...offers, ...res.content]);
        setLoading(false);
      });
    } else {
      const f = validateFilters(filters);
      getOffers(currentPage, perPage, setLoading, f).then((res) => {
        setHasMore(!res.last);
        setOffers([...offers, ...res.content]);
        setLoading(false);
      });
    }
  }, [currentPage]);

  useEffect(() => {
    if (
      JSON.stringify(defaultFilters) !== JSON.stringify(filters) &&
      JSON.stringify(prevFilters?.filters) !== JSON.stringify(filters) &&
      !filters.resetFilters
    ) {
      const f = validateFilters(filters);
      if (filters.origin !== prevFilters?.filters.origin) {
        delete f.destination;
        delete f.region;
        setActiveRegion('All');
      }

      if (filters.destination !== prevFilters?.filters.destination) {
        delete f.region;
        setActiveRegion('All');
      }

      setCurrentPage(0);

      getOffers(currentPage, perPage, setLoading, f).then((res) => {
        setHasMore(!res.last);
        setOffers([...res.content]);
        setLoading(false);
      });

      if (filters.origin !== prevFilters?.filters.origin) {
        const origin = filters.origin === 'All origins' ? null : filters.origin;
        // setActiveRegion('All');
        getOfferDestinationsByOrigin(origin, userStore?.country).then((res) => {
          setOfferCities({ ...offerCities, destinations: res });
          getRegions(res);
        });

        getAllOffers();
      }
    }


    if (filters.resetFilters) {
      setActiveRegion('All');
      setFilters(defaultFilters);
      setCurrentPage(0);
      const f = validateFilters(defaultFilters);
      getOffers(0, perPage, setLoading, f).then((res) => {
        setHasMore(!res.last);
        setOffers([...res.content]);
        setLoading(false);
      });
      const origin = filters.origin === 'All origins' ? null : filters.origin;
      getOfferDestinationsByOrigin(origin, userStore?.country).then((res) => {
        setOfferCities({ ...offerCities, destinations: res });
        getRegions(res);
      });

      getAllOffers();
    }
  }, [filters]);

  const getAllOffers = () => {
    const perPageForAll = 200;
    const f = validateFilters(filters);
    delete f.region;
    delete f.destination;
    getOffers(0, perPageForAll, setLoading, f).then((res) => {
      if (!res || !res.content || !res.content.length) {
        console.error(
          'getAllOffers: No content received from the server. Please check the server logs for errors.'
        );
        return;
      }

      const cheapestOffers = res.content.reduce((acc, curr) => {
        const sameDestination = acc.find(
          (o) => o.destination.code === curr.destination.code
        );
        if (sameDestination) {
          if (curr.price < sameDestination.price) {
            return acc.map((o) =>
              o.destination.code === curr.destination.code ? curr : o
            );
          }
          return acc;
        }
        return [...acc, curr];
      }, []);
      if (!cheapestOffers || !cheapestOffers.length) {
        console.error(
          'getAllOffers: No offers received from the server. Please check the server logs for errors.'
        );
        return;
      }

      setPlaces([...createGlobePlaces(cheapestOffers)]);
      setLoading(false);
    }).catch((err) => {
      console.error('getAllOffers: Error occurred while fetching offers.', err);
      setLoading(false);
    });
  };

  const handleRegionChange = (event, region) => {
    setActiveRegion(region);
    const activeRegion = region === 'All' ? null : region;
    setFilters({ ...filters, region: activeRegion });
  };
  
  const handleSelectRegionChange = (event) => {
    const region = event.target.value;
    setActiveRegion(region);
    const activeRegion = region === 'All' ? null : region;
    setFilters({ ...filters, region: activeRegion });
  };

  const handleSortChange = (event) => {
    const sort = event.target.value;
    setFilters({ ...filters, sort: sort });
  };

  return (
    <div>
      <div className={cn(styles.headingContainer)}>
        <h4 className={cn(styles.heading, 'font-fragment text-center')}>
          <div className={cn(styles.icon, 'text-white')}>
            <LocalOfferOutlinedIcon />
          </div>
          Check out our Best Flight Offers from{' '}
          {offerCities?.origins?.find((o) => o.code === filters.origin)?.name ||
            COUNTRIES[userStore?.country]}
        </h4>

        {width && (
          <ThemeProvider theme={theme}>
            <Formik
              enableReinitialize
              initialValues={filters}
              validate={debounce((value) => {
                setFilters({ ...value });
              }, 1000)}
            >
              {({ setFieldValue, values, errors, touched, resetForm }) => (
                <Form>
                  {width >= 1024 && (
                    <div className={styles.filtersContainer}>
                      {renderFilters({
                        setFieldValue,
                        values,
                        errors,
                        touched,
                      })}
                      {JSON.stringify(defaultFilters) !==
                        JSON.stringify(filters) && (
                        <Tooltip title="Reset filters">
                          <IconButton
                            color="inherit"
                            type="reset"
                            onClick={() => {
                              resetForm({ values: defaultFilters });
                              setFilters({
                                ...defaultFilters,
                                resetFilters: true,
                              });
                            }}
                            aria-label="clear"
                            sx={{
                              border: '1px solid #fff',
                              borderRadius: '50%',
                              color: '#fff',
                              width: '56px',
                              height: '56px',
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </div>
                  )}
                  {width < 1024 && (
                    <div className={styles.mobileFiltersWrapper}>
                      <div className='flex flex-col'>
                        <div
                          className={styles.filtersButton}
                          onClick={() => setMobileFilters(true)}
                        >
                          <TuneRoundedIcon className={'mr-2'} /> Filters
                        </div>
                        {(view === 'map' || view === 'globe') && filters.origin === 'All origins' && (
                          <div className="block text-xs leading-[0.7] text-red-400">Please select an origin from filters to show destinations for it on the map</div>
                        )}
                      </div>
                      <Dialog
                        open={mobileFilters}
                        fullWidth
                        PaperProps={{
                          style: {
                            margin: 0,
                            width: '100%',
                            bottom: 0,
                            position: 'absolute',
                            borderBottomLeftRadius: 0,
                            borderBottomRightRadius: 0,
                          },
                        }}
                      >
                        <div className={styles.filtersContainerMobile}>
                          <IconButton
                            color="inherit"
                            onClick={() => setMobileFilters(false)}
                            aria-label="close"
                            className={styles.closeMobileFilters}
                          >
                            <CloseIcon />
                          </IconButton>
                          {renderFilters({ setFieldValue, values })}

                          <div className={styles.filterDialogActions}>
                            <Button
                              variant="outlined"
                              type="button"
                              color="primary"
                              sx={{ textTransform: 'unset' }}
                              onClick={() => setMobileFilters(false)}
                            >
                              Close
                            </Button>

                            <Button
                              variant="contained"
                              type="button"
                              color="primary"
                              sx={{ textTransform: 'unset' }}
                              onClick={() => setMobileFilters(false)}
                            >
                              Apply
                            </Button>
                          </div>
                        </div>
                      </Dialog>

                      <div className={styles.sorting}>
                        {/* <Tooltip title="Sort alphabetically">
                          <IconButton
                            color="inherit"
                            type="reset"
                            onClick={() => {
                              if (filters.sort?.destination === 'desc') {
                                setFieldValue('sort', {
                                  ...filters.sort,
                                  destination: 'asc',
                                });
                              }
                              if (filters.sort?.destination === 'asc') {
                                setFieldValue('sort', {
                                  ...filters.sort,
                                  destination: null,
                                });
                              }
                              if (!filters.sort?.destination) {
                                setFieldValue('sort', {
                                  ...filters.sort,
                                  destination: 'desc',
                                });
                              }
                            }}
                            aria-label="clear"
                            sx={{
                              border: '1px solid #fff',
                              borderRadius: '50%',
                              color: '#fff',
                              width: '42px',
                              marginRight: '1.25rem',
                            }}
                          >
                            <SortByAlphaIcon />
                            {filters.sort?.destination === 'asc' && (
                              <ArrowRightAltIcon
                                sx={{
                                  position: 'absolute',
                                  right: '-21px',
                                  transform: 'rotate(90deg)',
                                }}
                              />
                            )}
                            {filters.sort?.destination === 'desc' && (
                              <ArrowRightAltIcon
                                sx={{
                                  position: 'absolute',
                                  right: '-21px',
                                  transform: 'rotate(-90deg)',
                                }}
                              />
                            )}
                          </IconButton>
                        </Tooltip> */}
                        {JSON.stringify(defaultFilters) !==
                          JSON.stringify(filters) && (
                          <Tooltip title="Reset filters">
                            <IconButton
                              color="inherit"
                              type="reset"
                              onClick={() => {
                                resetForm({ values: defaultFilters });
                                setFilters({
                                  ...defaultFilters,
                                  resetFilters: true,
                                });
                              }}
                              aria-label="clear"
                              sx={{
                                border: '1px solid #fff',
                                borderRadius: '50%',
                                color: '#fff',
                                width: '42px',
                              }}
                            >
                              <CloseIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                      </div>
                    </div>
                  )}
                </Form>
              )}
            </Formik>
            <div className="container flex-col">
              <div className="flex flex-col lg:flex-row justify-center items-center w-full">
                <div className="text-white text-center text-nowrap whitespace-nowrap pr-3 hidden lg:block">
                  Offers view:{' '}
                </div>
                <StyledToggleButtonGroup
                  orientation="horizontal"
                  size="large"
                  value={view}
                  exclusive
                  fullWidth={true}
                  onChange={handleViewChange}
                >
                  <ToggleButton
                    value="card"
                    aria-label="card"
                    className={styles.viewButton}
                  >
                    <div
                      style={{ transform: 'skew(-30deg)' }}
                      className="flex flex-col lg:flex-row items-center"
                    >
                      <ViewModuleIcon />{' '}
                      <span className="text-xs lg:text-lg pl-1">Card</span>
                    </div>
                  </ToggleButton>
                  <ToggleButton
                    value="table"
                    aria-label="table"
                    className={styles.viewButton}
                  >
                    <div
                      style={{ transform: 'skew(-30deg)' }}
                      className="flex flex-col lg:flex-row items-center"
                    >
                      <ViewListIcon />{' '}
                      <span className="text-xs lg:text-lg pl-1">List</span>
                    </div>
                  </ToggleButton>
                  <ToggleButton
                    value="globe"
                    aria-label="globe"
                    className={styles.viewButton}
                  >
                    <div
                      style={{ transform: 'skew(-30deg)' }}
                      className="flex flex-col lg:flex-row items-center"
                    >
                      <LanguageOutlinedIcon />{' '}
                      <span className="text-xs lg:text-lg pl-1">Globe</span>
                    </div>
                  </ToggleButton>
                  <ToggleButton
                    value="map"
                    aria-label="map"
                    className={styles.viewButton}
                  >
                    <div
                      style={{ transform: 'skew(-30deg)' }}
                      className="flex flex-col lg:flex-row items-center"
                    >
                      <MapOutlinedIcon />{' '}
                      <span className="text-xs lg:text-lg pl-1">Map</span>
                    </div>
                  </ToggleButton>
                </StyledToggleButtonGroup>
              </div>

              {process.env.prod === 'false' && (
                <p className='text-white text-center text-sm'>Offers shown:  {offers?.length}. Places shown:  {places?.length}</p>
              )}
            </div>
          </ThemeProvider>
        )}
      </div>
      {loading && (
        <LinearProgress color="secondary" />
      )}

      {regions && 
        (view === 'card' || view === 'table') && 
        (filters.destination === 'All destinations' || filters.destination === '') && (
        <ThemeProvider theme={theme}>
          <div className="container flex justify-between pt-3 px-3">
            <div className={cn(styles.regionFilters, 'w-full')}>
              <div className="flex flex-col lg:flex-row items-center w-full">
                {/* <div className="text-center text-nowrap whitespace-nowrap pr-3 hidden lg:block">
                  Regions:{' '}
                </div> */}

                {width < 1024 ? (
                  <div className='w-full flex'>
                    <Select
                      value={activeRegion}
                      onChange={handleSelectRegionChange}
                      fullWidth
                      className='bg-white font-bold'
                      variant="outlined"
                      sx={{
                        color: '#202020',
                        '& .MuiOutlinedInput-input': {
                          padding: '8px',
                          border: '2px solid transparent',
                          borderColor: activeRegion !== defaultFilters.region ? '#BB9269' : 'currentColor'
                        }
                      }}
                    >
                      <MenuItem value="All">All regions</MenuItem>
                      {regions.map((region) => {
                        return (
                          <MenuItem
                            key={region.id}
                            value={region.id}
                          >
                            {region.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </div>
                ) : (
                  <ToggleButtonGroup
                    orientation={width < 1024 ? 'vertical' : 'horizontal'}
                    size="large"
                    value={activeRegion}
                    fullWidth={width < 1024}
                    exclusive
                    onChange={handleRegionChange}
                  >
                    <ToggleButton
                      value={'All'}
                      aria-label={'All regions'}
                      className={styles.regionButton}
                      selected={activeRegion === 'All'}
                    >
                      <div
                        className="flex flex-col lg:flex-row items-center"
                      >
                        All regions
                      </div>
                    </ToggleButton>
                    {regions.map((region) => {
                      if (region.id === 0 && !userStore.is_admin) return null;
                      return (
                       
                        <ToggleButton
                          value={region.id}
                          aria-label={region.name}
                          key={region.id}
                          className={cn(styles.regionButton, region.id === 0 && 'bg-red-100')}
                          selected={region.id === activeRegion}
                        >
                          <div
                            className="flex flex-col lg:flex-row items-center"
                          >
                            {region.name}
                          </div>
                        </ToggleButton>
                      );
                    })}
                  </ToggleButtonGroup>
                )}
              </div>
            </div>

            <div className={cn(styles.sortBySelect, 'flex items-center justify-end ml-auto')}>
              {/* <span className={cn(styles.sortByTitle, 'font-libre hidden lg:block')}>Sort by:</span> */}

              <FormControl variant="outlined" sx={{ ml: 1 }}>
                <InputLabel id="sortBy">Sort by:</InputLabel>
                <Select
                  labelId="sortBy"
                  label="Sort by"
                  value={filters.sort}
                  onChange={handleSortChange}
                  IconComponent={KeyboardArrowDownRoundedIcon}
                  className={cn('bg-white', filters.sort !== defaultFilters.sort && 'font-bold')}
                  sx={{
                    color: '#202020',
                    '& .MuiOutlinedInput-input': {
                      padding: '8px',
                    }
                  }}
                >
                  {sortOptions.map((option) => (
                    <MenuItem
                      value={option.value}
                      key={option.label}
                      data-value={option.value}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
        </ThemeProvider>
      )}

      <div ref={globeContainerRef}>
        {(view === 'card' || view === 'table') && (
          <div className={cn(styles.listContainer, 'container')}>
            <div className={cn(styles.regionFilters)}></div>
            <div
              className={cn(
                styles.list,
                view === 'card' &&
                  'p-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5',
                view === 'table' &&
                  'p-4 grid grid-cols-1 lg:grid-cols-2 gap-x-6',
                view === 'table',
                styles[view]
              )}
            >
              {offers.map((offer, index) => {
                return (
                  <OfferCard
                    data={offer}
                    key={index}
                    searchSource={
                      'Main page ' + view === 'card' ? '(card)' : '(table)'
                    }
                    type={view}
                    showOrigin={true}
                  />
                );
              })}
            </div>
            {hasMore && (
              <div className={'w-1/2 lg:w-1/4 mx-auto my-4'}>
                <VintageButton
                  onClick={() => setCurrentPage(currentPage + 1)}
                  label={'Show more offers'}
                />
              </div>
            )}

            {/* {loading && <div className={styles.loader} />} */}
            {!loading && offers.length === 0 && (
              <>
                <div className='text-center'>
                  <TravelExploreIcon className='mx-auto mb-4 text-5xl text-gray-400'/>
                </div>
                <div className={styles.noResults}>No offers found</div>
                <p className='text-center mt-3'>Try changing or resetting your filters</p>
              </>
              
            )}
          </div>
        )}

        {view === 'globe' &&  (
          <>
            {places && (
              <div className="relative">
                <BCGlobe
                  globeImageUrl="/_next/image?url=images%2Fglobe.jpeg&w=3000&q=80"
                  backgroundImageUrl="/_next/image?url=images%2Fnight-sky.webp&w=2000&q=95"
                  width={globeWidth}
                  waitForGlobeReady={true}
                  height={globeHeight}
                  backgroundColor={'#001724'}
                  data={places}
                  altitude={globeAltitude}
                  lat={(d) => d.latitude}
                  lng={(d) => d.longitude}
                  pointColor={() => '#00454A'}
                  pointRadius={0.5}
                  atmosphereAltitude={0.25}
                  onGlobeReady={() => sendGlobeEventToTagManager('Offers Globe init')}
                  emitZoom={(zoom) => {
                    setGlobeCurrentAltitude(zoom);
                  }}
                  onClick={() => sendGlobeEventToTagManager('Offer click')}
                  isOffersGlobe={true}
                />

                <div className={styles.globeBtn}>
                  <IconButton
                    color="inherit"
                    role="button"
                    aria-label="Zoom in"
                    onClick={() => {
                      setGlobeAltitude(globeCurrentAltitude - 0.5);
                      sendGlobeEventToTagManager('Offers Globe zoom in');
                    }}
                    sx={{
                      color: 'white',
                      fontSize: 24,
                      backgroundColor: '#A12552',

                      '&:hover': {
                        backgroundColor: '#A12552',
                      },
                    }}
                  >
                    <AddIcon />
                  </IconButton>

                  <IconButton
                    color="inherit"
                    role="button"
                    aria-label="Zoom out"
                    onClick={() => {
                      setGlobeAltitude(globeCurrentAltitude + 0.5);
                      sendGlobeEventToTagManager('Offers Globe zoom out');
                    }}
                    sx={{
                      color: 'white',
                      fontSize: 24,
                      backgroundColor: '#A12552',

                      '&:hover': {
                        backgroundColor: '#A12552',
                      },
                    }}
                  >
                    <RemoveIcon />
                  </IconButton>
                </div>
              </div>
            )}

            {loading && (
              <Box
                sx={{
                  height: globeHeight,
                  backgroundColor: '#131313',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Skeleton
                  variant="circular"
                  width={globeHeight - globeHeight * 0.1}
                  height={globeHeight - globeHeight * 0.1}
                  sx={{ bgcolor: 'grey.800' }}
                />
              </Box>
            )}
          </>
        )}
      </div>

      {view === 'map' && places && (
        <>
          {!loading && places.length > 0 && (
            <OffersMap
              center={{
                lat: places[0]?.latitude,
                lng: places[0]?.longitude,
              }}
              containerStyle={{
                width: '100%',
                height: '100%',
                minHeight: 'inherit',
              }}
              offerFrom={places[0]}
              offers={places}
            ></OffersMap>
          )}
        </>
      )}

      {(view === 'map' || view === 'globe') && !loading && (!places || places.length === 0) && (
         <>
         <div className='text-center'>
           <TravelExploreIcon className='mx-auto mb-4 text-5xl text-gray-400'/>
         </div>
         <div className={styles.noResults}>No offers found</div>
         <p className='text-center mt-3'>Try changing or resetting your filters</p>
       </>
      )}
    </div>
  );
}
