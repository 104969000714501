'use client';
import { useEffect, useRef, useState, forwardRef } from 'react';
import cn from 'classnames';
import styles from '../Map/Map.module.scss';
import CurrencyFormat from 'react-currency-format';
import getSymbolFromCurrency from 'currency-symbol-map';
import { useSelector } from 'react-redux';
import { renderToStaticMarkup } from 'react-dom/server';
import { useWindowWidth } from '@react-hook/window-size';
import { Raycaster, TextureLoader, SphereGeometry, MeshPhongMaterial, Mesh } from 'three'
import FlightIcon from '@mui/icons-material/Flight';
import { debounce } from '@material-ui/core/utils';
import ReactDOM from 'react-dom';
import { OfferCard } from '../OfferCard/OfferCard';
// import dynamic from 'next/dynamic';

const minAltitude = 0.35;
const maxAltitude = 4;
const defaultAltitude = 1.7;
const mobileDefaultAltitude = 2;

// const Globe = dynamic(() => import('react-globe.gl'), {
//   ssr: false, // Ensure this only runs on the client side
// });

const GlobeWithRef = forwardRef((props, ref) => {
  return <Globe {...props} ref={ref} />;
});

GlobeWithRef.displayName = 'GlobeWithRef';

let Globe = () => null;
if (typeof window !== 'undefined') Globe = require('react-globe.gl').default;
export const BCGlobe = (props) => {
  const width = useWindowWidth();
  const [arcs, setArcs] = useState([]);
  const [marks, setMarks] = useState([]);
  const [raycaster, setRaycaster] = useState(null);
  const [mouse, setMouse] = useState({ x: 0, y: 0 });
  const [globeAltitude, setGlobeAltitude] = useState(width > 1024 ? defaultAltitude : mobileDefaultAltitude);
  // const [points, setPoints] = useState([]); // debug zooming

  const [arcHovered, setArcHovered] = useState(null);
  
  const [currentZoom, setCurrentZoom] = useState(globeAltitude);
  const [hoveredMarker, setHoveredMarker] = useState(null);
  const [cardPosition, setCardPosition] = useState({ x: 0, y: 0 });
  const [isHoveringCard, setIsHoveringCard] = useState(false);
  const [isHoveringMarker, setIsHoveringMarker] = useState(false);
  const userStore = useSelector((state) => state.user.value);
  const globeEl = useRef();
  const isTouchDevice = typeof window.ontouchstart !== 'undefined' || 'ontouchstart' in window || navigator.maxTouchPoints > 0 || (navigator.userAgent.toLowerCase().includes('iphone') && navigator.userAgent.toLowerCase().includes('safari'));
  // const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
   setGlobeAltitude(width > 1024 ? defaultAltitude : mobileDefaultAltitude);
  }, [width]);

  useEffect(() => {
    if (props.data?.length > 2) {
      if (userStore?.country === 'AU') {
        globeEl?.current?.pointOfView({ lng: 117, lat: -2, altitude: globeAltitude }, 3000);
        return;
      }

      if (props.data[0]) {
        const centerLng = props.data[0].longitude + 7;
        const centerLat = props.data[0].latitude - 7;

        globeEl?.current?.pointOfView({ lng: centerLng, lat: centerLat, altitude: globeAltitude }, 3000);
      }
  }
  }, [props.data, userStore]); //marks

  useEffect(() => {
    const currentAltitude = globeEl?.current?.pointOfView().altitude;
    if (Math.abs(props.altitude - currentAltitude) > 0.1) {
      if (props.altitude < minAltitude) {
        globeEl?.current?.pointOfView({ altitude: minAltitude }, 100);
      } else if (props.altitude > maxAltitude) {
        globeEl?.current?.pointOfView({ altitude: maxAltitude }, 100);
      } else {
        globeEl?.current?.pointOfView({ altitude: props.altitude }, 100);
      }
    }
  }, [props.altitude]);

  useEffect(() => {
    const globe = globeEl.current;

    // if (!globe || typeof globe.renderer !== 'function') {
    //   // Ensure the globe and renderer function exist
    //   return;
    // }

    const raycasterInstance = new Raycaster();
    setRaycaster(raycasterInstance);

    const handleMouseMove = (event) => {
      event.preventDefault();

      // Calculate mouse position relative to the canvas
      const rect = globe?.renderer().domElement.getBoundingClientRect();
      const x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
      const y = -((event.clientY - rect.top) / rect.height) * 2 + 1;
      setMouse({ x, y });
    };


    globe?.renderer().domElement.addEventListener('mousemove', handleMouseMove);
    // globe.renderer().domElement.addEventListener('wheel', handleMouseMove);

    return () => {
      globe?.renderer().domElement.removeEventListener('mousemove', handleMouseMove);
      // globe.renderer().domElement.removeEventListener('wheel', handleMouseMove);
    };
  }, []);

  useEffect(() => {
    if (!raycaster) return;
    // const initializationTimer = setTimeout(() => {
    //   setIsInitialized(true);
    // }, 500);
  
    const globe = globeEl.current;
    raycaster.setFromCamera(mouse, globe.camera());

    const handleWindowScroll = (event) => {
      event.preventDefault();
      const deltaY = event.deltaY;
      const shouldScrollUp = deltaY < 0 && window.scrollY > 0;
      const shouldScrollDown = deltaY > 0;

      if (shouldScrollUp || shouldScrollDown) {
        window.scrollBy(0, deltaY);
        window.dispatchEvent(new WheelEvent('wheel', event));
      } else {
        globeEl?.current?.pointOfView({ altitude: currentZoom }, 0);
      }
    };

    if (globe?.scene() && globe?.scene().children[3]) {
      const intersects = raycaster.intersectObjects(globe.scene().children[3]?.children, true);

      if (intersects) {
        if (intersects?.length > 0) {
          // Mouse is hovering over the globe, handle zooming
          globe.renderer().domElement.removeEventListener('wheel', handleWindowScroll);
        } else {
          // Mouse is not over the globe, remove event listener for zooming
          globe.renderer().domElement.addEventListener('wheel', handleWindowScroll);
        }
      }
    }
    

    return () => {
      // Clean up event listener when component unmounts
      globe?.renderer().domElement.removeEventListener('wheel', handleWindowScroll);
      // clearTimeout(initializationTimer);
    };
  }, [mouse]);

  const mapToMarkers = (pointsData) => {
    setMarks(
      pointsData.map((data, i) => ({
        lat: props.lat(data),
        lng: props.lng(data),
        price: Math.round(data.offer?.price || 0),
        size: 100,
        color: i ? '#00454A' : '#FAF8F4',
        label: data.name,
        code: data.code,
        offer: data.offer,
        isPoint: data.isPoint,
      }))
    );
  };

  // const mapToPoints = (pointsData) => {
  //   let color;
  //   console.log(userStore.country);
  //   switch (userStore.country) {
  //     case 'GB':
  //       color = '#000000';
  //       break;
  //     case 'NO':
  //       color = '#eb8710';
  //       break;
  //     case 'US':
  //       color = '#7e183d';
  //       break;
  //     case 'SE':
  //       color = '#FFFFFF';
  //       break;
  //     case 'AU':
  //       color = '#00454A';
  //       break;
  //     default:
  //       color = '#000000';
  //       break;
  //   }
  //   setPoints(
  //     pointsData.map((data) => ({
  //       lat: props.lat(data),
  //       lng: props.lng(data),
  //       size: 0.5,
  //       text: data.name,
  //       radius: '50%',
  //       color: color,
  //       isPoint: true,
  //     }))
  //   );
  // }


  useEffect(() => {
    const globe = globeEl.current;
    if (globe && globe.scene().children.find(scene => scene.name === 'Clouds') === undefined) {

      // Auto-rotate
      // globe.controls().autoRotate = true;
      // globe.controls().autoRotateSpeed = 0.35;

      // Add clouds sphere
      const CLOUDS_IMG_URL =  '/_next/image?url=images%2Fclouds.png&w=2000&q=90';
      const CLOUDS_ALT = 0.004;
      const CLOUDS_ROTATION_SPEED = -0.01; // deg/frame

      new TextureLoader().load(CLOUDS_IMG_URL, cloudsTexture => {
        const clouds = new Mesh(
          new SphereGeometry(globe.getGlobeRadius() * (1 + CLOUDS_ALT), 75, 75),
          new MeshPhongMaterial({ map: cloudsTexture, transparent: true })
        );
        clouds.name = 'Clouds';
        globe.scene().add(clouds);

        (function rotateClouds() {
          clouds.rotation.y += CLOUDS_ROTATION_SPEED * Math.PI / 180;
          requestAnimationFrame(rotateClouds);
        })();
      });
    }
  }, []);

  useEffect(() => {
    // updateArcs(null);
    // mapToMarkers(props.data);
    if (props.data?.length > 2) {
      handlePointsZoom(currentZoom);
    } else {
      mapToMarkers(props.data);
      updateArcs(null, props.data);

      globeEl?.current?.pointOfView({ lng: props.data[1]?.longitude || 0, lat: props.data[1]?.latitude || 0, altitude: globeAltitude }, 3000);
    }
  }, [props.data]);

  function handlePointsZoom(zoom) {
    if (zoom < minAltitude) {
      setCurrentZoom(minAltitude);
      globeEl?.current?.pointOfView({ altitude: minAltitude }, 0);
    } else if (zoom > maxAltitude) {
      setCurrentZoom(maxAltitude);
      globeEl?.current?.pointOfView({ altitude: maxAltitude }, 0);
    } else {
      setCurrentZoom(zoom);
    }

    function coordinateHashArray(size, coordinates) {
      let hashLatitude = Math.ceil(coordinates.latitude / 1) + 180
      let hashLongitude = Math.ceil(coordinates.longitude / 1) + 180
      let hash = []
      for (let d = -size; d <= size; d++) {
        // * - square on the map 1*1 degree
        // City name size:
        // * * *
        hash.push(hashLatitude * 360 + hashLongitude + d)
        if (size >= 3) {
          // * * * * * * *
          // * * * * * * *
          hash.push((hashLatitude - 1) * 360 + hashLongitude + d)
        }
        if (size >= 4) {
          hash.push((hashLatitude + 1) * 360 + hashLongitude + d)
        }
        if (size >= 5) {
          hash.push((hashLatitude - 2) * 360 + hashLongitude + d)
          hash.push((hashLatitude + 2) * 360 + hashLongitude + d)
        }
        if (size >= 15) {
          hash.push((hashLatitude - 3) * 360 + hashLongitude + d)
          hash.push((hashLatitude + 3) * 360 + hashLongitude + d)
          hash.push((hashLatitude - 4) * 360 + hashLongitude + d)
          hash.push((hashLatitude + 4) * 360 + hashLongitude + d)
        }
      }
      return hash;
    }

    // setCurrentZoom(zoom);
    let visibleOffersHash = new Set();
    let markSize = 1
    if (width > 1024) {
      if (zoom > 2.24) {
        markSize = 18;
      } else if (zoom > 1.98) {
        markSize = 12;
      } else if (zoom > 0.867) {
        markSize = 7;
      } else if (zoom > 0.426) {
        markSize = 3;
      } else if (zoom > 0.368) {
        markSize = 2;
      } else {
        markSize = 1;
      }
    } else {
      if (zoom > 3) {
        markSize = 100
      } else if (zoom > 1) {
        markSize = 18;
      } else if (zoom > 0.8) {
        markSize = 12;
      } else if (zoom > 0.37) {
        markSize = 7;
      } else {
        markSize = 1;
      }
    }

    let visibleOffers = [];
    let arcpoints = [];
    visibleOffers = props.data.map(point => {
      point.isPoint = false;
      return point;
    });
    if (markSize >= 100) {
      // Show all as points
      visibleOffers.forEach(p=> {p.isPoint = true})
    } else if (zoom > 0.37) {
      let isFirst = true
      for (const dest of visibleOffers) {
        if (isFirst) {
          isFirst = false
          arcpoints.push(dest); // The first arc should be origin city
          continue
        }
        let hashArray = coordinateHashArray(markSize, dest)
        let skipPoint = false
        for (const hashEl of hashArray) {
          if (visibleOffersHash.has(hashEl)) {
            skipPoint = true
            break
          }
        }
        if (!skipPoint) {
          dest.isPoint = false;
          hashArray.forEach(visibleOffersHash.add, visibleOffersHash)
          arcpoints.push(dest);
        } else {
          dest.isPoint = true;
        }
      }
      /*
      if (true) { // debug zooming
        console.log(zoom, markSize);
        let p= [];
        for (let la = -20; la < 80; la++) { // maximum latitude is 90
          for (let ln = -20; ln < 150; ln++) {
            let pointHash = coordinateHashArray(0, {latitude: la, longitude: ln})
            let color = 'green'
            if (visibleOffersHash.has(pointHash[0])) {
              color = 'red'
            }
            p.push(
                {
                  lat: la,
                  lng: ln,
                  size: Math.random() / 3,
                  color: color
                }
            )
          }
        }
        for (const dest of visibleOffers) {
          p.push(
              {
                lat: dest.latitude,
                lng: dest.longitude,
                size: Math.random() / 3,
                color: "orange"
              }
          )
        }
        setPoints(p)
      } */
    } else {
      arcpoints = visibleOffers
    }
    mapToMarkers(visibleOffers);
    updateArcs(null, arcpoints);
  }

  function updateArcs(arc, offersData) {
    function getArcStroke(d) {
      return arc.endLat === props.lat(d) && arc.endLng === props.lng(d)
        ? 0.1
        : 0.05;
    }

    if (!offersData) {
      setArcs([]);
    }
    setArcs(
      offersData
        .map((d, i) => {
          if (!i) return null;
          return {
            startLat: props.lat(props.data[0]),
            endLat: props.lat(d),
            startLng: props.lng(props.data[0]),
            endLng: props.lng(d),
            stroke: arc ? getArcStroke(d) : 0.05,
            iconPosition: {
              lat: (props.lat(d) + props.lat(props.data[0])) / 2,
              lng: (props.lng(d) + props.lng(props.data[0])) / 2,
            },
          };
        })
        .filter((d) => !!d)
    );

  }

  const handleZoom = (zoom) => {
    // if (!isInitialized) return;
 
    // props.emitZoom(zoom.altitude);
    // setCurrentZoom(zoom.altitude);
    if (currentZoom.toFixed(3) !== zoom.altitude.toFixed(3)) {
      props.emitZoom(zoom.altitude);
      handlePointsZoom(zoom.altitude);
    }
  }

  const debouncedHandleZoom = debounce(handleZoom, 0);

  const renderOfferCard = () => {
    if (!hoveredMarker) return null;
    if (!isHoveringCard && !isHoveringMarker) return null;

    const positionStyle = isTouchDevice
    ? {
        position: 'fixed',
        bottom: '20px', // Adjust this value for desired vertical position
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: 1000,
        minWidth: '300px'
      }
    : {
        position: 'absolute',
        top: `${cardPosition.y - 40}px`,
        left: `${cardPosition.x - 40}px`,
        zIndex: 1000,
      };


    return ReactDOM.createPortal(
      <div
        id="offer-card"
        style={positionStyle}
        onMouseEnter={() => {
          setIsHoveringCard(true);
          setIsHoveringMarker(false);
        }} // Keep the card visible
        onMouseLeave={() => {
          setIsHoveringCard(false);
          if (!isHoveringMarker) {
            setHoveredMarker(null);
          }
        }}
        onClick={props.onClick}
      >
        <OfferCard data={hoveredMarker}
          searchSource={'Globe marker'}
          closeDetails={() => {
            setIsHoveringCard(false);
            setIsHoveringMarker(false);
            setHoveredMarker(null);
            sessionStorage.setItem('BC-offerIDOpened', null);
          }}
        />
      </div>,
      document.body
    );
  };

  useEffect(() => {
    if (!hoveredMarker || !isTouchDevice) return;

    const handleOutsideTouch = (event) => {
      const cardElement = document.getElementById('offer-card');
      if (cardElement && !cardElement.contains(event.target)) {
        setHoveredMarker(null);
        setIsHoveringCard(false);
        setIsHoveringMarker(false);
      }
    };

    document.addEventListener('touchstart', handleOutsideTouch);

    return () => {
      document.removeEventListener('touchstart', handleOutsideTouch);
    };
  }, [hoveredMarker, isTouchDevice]);


  return (
    <>
      <GlobeWithRef
          ref={globeEl}
          {...props}
          controls={{
            autoRotate: false,
            enableDamping: false,
            zoomSpeed: 0.5
            // dampingFactor: 2, // Higher values make the rotation stop faster
            // rotateSpeed: 3.0, // Increase rotate speed to make dragging more responsive
            // dynamicDampingFactor: 0.5, // Adjust to make the globe stop faster
          }}
          globeControlsOptions={{
            zoomSpeed: 0.5,
            minDistance: minAltitude,
            maxDistance: maxAltitude,
          }}
          rendererConfig={{
            antialias: true
          }}
          htmlElementsData={marks}
          htmlAltitude={(data) => {
            if (data.isPoint) {
              return 0;
            }
            return 0.02;
          }}
          arcsData={arcs}
          arcColor={(a) => {
            return arcHovered &&
              a.endLat === arcHovered.endLat &&
              a.endLng === arcHovered.endLng
              ? '#d4a976'
              : '#3C6562';
          }}
          // arcColor={() => '#3C6562'}
          // arcStroke={(a) => {
          //   return arcHovered &&
          //     a.endLat === arcHovered.endLat &&
          //     a.endLng === arcHovered.endLng
          //     ? 1
          //     : 0.5;
          // }}
          arcStrokeWidth={0.5}
          //arcAltitude={0.1}
          arcAltitudeAutoScale={0.35} //Need this to have arcsto be over the Globe
          arcsTransitionDuration={0}
          arcDashAnimateTime={0}
          arcCurveResolution={30}
          arcDashLength={0.15}
          onArcHover={(arc) => {
            if (arc) {
              arc.stroke = 0.8;
            }
            
            setArcHovered(arc);
          }}
          onZoom={debouncedHandleZoom}
          pointsTransitionDuration={0}
          // debug zooming
          /*
          pointsData={points}
          pointAltitude={0.01}
          pointColor="color"
          pointRadius={0.1}
          */
          // labelLat={(d) => {
          //   return d.lat;
          // }}
          // labelLng={(d) => d.lng}
          // labelSize={(d) => {
          //   return d.size;
          // }}
          // labelColor={(d) => {
          //   return d.color;
          // }}
          // labelText={(d) => {
          //   return d.text;
          // }}
          // labelIncludeDot={true}
          // labelDotRadius={0.01}
          // labelAltitude={0.005}
          // labelsTransitionDuration={0}
          // onLabelHover={(label) => {
          //   console.log(label);
          // }}
          // labelResolution={2}
          htmlElement={(d) => {
            if (d.isPoint) {
              const el = document.createElement('div');
              el.style.position = 'absolute';
              // el.style.width = '15px';
              // el.style.height = '15px';
              // el.style.borderRadius = '50%';
              // el.style.backgroundColor = '#385179'; // '#4285f4';
              // el.style.cursor = 'pointer';
              // el.style.border = '1px solid #FAF8F4';
              el.style['pointer-events'] = 'auto';

              const point = renderToStaticMarkup(
                <div className={cn(styles.pointMarker)}>
                  <div className={cn(styles.pointMarkerIcon)}>
                    <div className='w-full h-full flex items-center justify-center cursor-pointer'>
                      <FlightIcon sx={{ fontSize: 11, color: '#fff' }}></FlightIcon>
                    </div>
                  </div>
                  <span>{d.label}</span>
                </div>
              );
              el.title = d.label;
              el.innerHTML = point;

              el.onclick = () => {
                props.onClick(d.offer);
                globeEl?.current?.pointOfView({ ...d, altitude: 1 }, 1000);
              };

              return el;
            } else {
              const el = document.createElement('div');
              if (
                d.lat !== props.lat(props.data[0]) &&
                d.lng !== props.lng(props.data[0])
              ) {
                const markerWithPrice = renderToStaticMarkup(
                  <div className={cn(styles.customMarker)}>
                    <div className={styles.priceWrapper}>
                      <CurrencyFormat
                        thousandSeparator
                        displayType={'text'}
                        prefix={`${getSymbolFromCurrency(userStore?.currency)}`}
                        value={d.price}
                      />
                      {/* {width > 1024 ? ' | ' : ''}
                      {width > 1024 ? d.label : ''} */}
                      {width > 1024 ? ' | ' : ''}
                      {width <= 1024 ? (<br/>) : ''}
                      <span className={styles.labelText}>{d.label}</span>
                    </div>
                  </div>
                );
                const markerWithDirection =
                  width > 1024
                    ? renderToStaticMarkup(
                        <div
                          className={cn(styles.customMarker, styles.currentMarker)}
                        >
                          <div className={styles.priceWrapper}>
                          <CurrencyFormat
                              thousandSeparator
                              displayType={'text'}
                              prefix={`${getSymbolFromCurrency(userStore?.currency)}`}
                              value={d.price}
                            />
                            {width > 1024 ? ' | ' : ''}
                            {width > 1024 ? d.label : ''}
                          </div>
                        </div>
                      )
                    : renderToStaticMarkup(
                        <div
                          id={'mobileDirection'}
                          className={cn(styles.customMarker, styles.currentMarker)}
                        >
                          <div className={styles.priceWrapper}>{d.label}</div>
                        </div>
                      );
                el.innerHTML = markerWithPrice;
                el.style.width = width > 1024 ? `${d.size}px` : '30px';
                el.style.transform = 'translateX(-50%)';
                el.style.top = '-20px';
                // el.style.left = '-10px';
                el.style['pointer-events'] = 'auto';
              
                el.style.cursor = 'pointer';

                el.onclick = () => {
                  props.onClick(d.offer);
                  if (width < 1024) {
                    const active = el.getAttribute('active');
                    console.log(active);
                    if (active) {
                      d.action();
                      el.innerHTML = markerWithPrice;
                      el.removeAttribute('active');
                    } else {
                      el.innerHTML = markerWithDirection;
                      el.setAttribute('active', true);
                    }

                    setIsHoveringMarker(true);
                    setCardPosition({ x: event.pageX, y: event.pageY });
                    setHoveredMarker(d.offer);
                    if (isTouchDevice) {
                      setCardPosition(null);
                    }
                  }
                  globeEl?.current?.pointOfView({ ...d, altitude: 1 }, 1000);
                };
                // el.onmouseover = (event) => {
                //   setHoveredMarker(d.offer); 
                //   setCardPosition({ x: event.pageX, y: event.pageY }); 
                // };
              
                // el.onmousemove = (event) => {
                //   setCardPosition({ x: event.pageX, y: event.pageY }); 
                // };

                const handleMouseEnter = (event) => {
                  setIsHoveringMarker(true);
                  if (!hoveredMarker) {
                    setCardPosition({ x: event.pageX, y: event.pageY });
                    setHoveredMarker(d.offer);
                  }
                };

                const handleMouseLeave = () => {
                  setIsHoveringMarker(false);
                  if (!isHoveringCard) setHoveredMarker(null);
                };

                const handleTouchStart = (event) => {
                  setIsHoveringMarker(true);
                  setCardPosition({ x: event.touches[0].pageX, y: event.touches[0].pageY });
                  setHoveredMarker(d.offer);
                };

                const handleTouchEnd = () => {
                  setIsHoveringMarker(false);
                  if (!isHoveringCard) setHoveredMarker(null);
                };

                el.addEventListener('mouseenter', handleMouseEnter);
                el.addEventListener('mouseleave', handleMouseLeave);

                if (isTouchDevice) {
                  el.addEventListener('touchstart', handleTouchStart);
                  el.addEventListener('touchend', handleTouchEnd);
                }
                
                // el.onmouseover = debounce(() => {
                //   if (width > 1024) {
                //     el.innerHTML = markerWithDirection;
                //   }
                // }, 300);
                // el.onmouseleave = debounce(() => {
                //   if (width > 1024) {
                //     el.innerHTML = markerWithPrice;
                //   }
                // }, 300);
              } else {
                el.innerHTML = `<svg style="animation: bounce 2s ease infinite" viewBox="-4 0 36 36">
          <path fill="#D4A976" d="M14,0 C21.732,0 28,5.641 28,12.6 C28,23.963 14,36 14,36 C14,36 0,24.064 0,12.6 C0,5.641 6.268,0 14,0 Z"></path>
          <circle fill="#FAF8F4" cx="14" cy="14" r="7"></circle>
        </svg> <div style=" margin-left: -35%; margin-top:-2px; text-align: center;"><span style="font-size: 10px; background-color: #D4A976; padding: 2px; width: 135%; border-radius: 4px; white-space:nowrap;">${d.label}</span> </div>`;
                el.style.color = d.color;
                el.style.width = `24px`;
                el.style.top = '-12px';
                el.style.verticalAlign = 'middle';
                el.style['pointer-events'] = 'auto';
              }

              return el;
            }
          }}
      />
      {props.isOffersGlobe && renderOfferCard()}
    </>
    
  );
};
