import styles from './OfferCard.module.scss';
import cn from 'classnames';
import { ImageWithFallback } from '../ImageWithFallback/ImageWithFallback';
import { Button, createTheme, Dialog, TextField, Tooltip } from '@material-ui/core';
import { setImageUrl } from '../../utils/setImageUrl';
import { getCabinClass } from '../FlightSearchResults/utils/flightUtils';
import CurrencyFormat from 'react-currency-format';
import getSymbolFromCurrency from 'currency-symbol-map';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import moment from 'moment';
import parse from 'html-react-parser';
import CloseIcon from '@mui/icons-material/Close';
import AnnouncementOutlinedIcon from '@mui/icons-material/AnnouncementOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import TravelExploreOutlinedIcon from '@mui/icons-material/TravelExploreOutlined';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import IosShareIcon from '@mui/icons-material/IosShare';
import { useWindowWidth } from '@react-hook/window-size';
import { createRef, useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import { CABIN_CLASSES } from '../../utils/cabinClasses';
import { getTextEditorParseOptions } from '../utils/textEditorParseOptions';
import InView from 'react-intersection-observer';
import { useRouter } from 'next/router';
import TagManager from 'react-gtm-module';
import { useSelector } from 'react-redux';
import { httpGet } from '../../utils/httpClient';
const FlightSearch = dynamic(() => import('../FlightSearch/FlightSearch'));

const theme = createTheme({
  palette: {
    primary: {
      main: '#00454A',
    },
    secondary: {
      main: '#0a0a0a',
    },
  },
  typography: {
    fontFamily: '"Libre Franklin", sans-serif',
    color: '#0a0a0a',
  },
});

const getSimilarOffers = async (origin, destination) => {
  const url = `/api/offer/items?page=0&size=20&origin=${origin}&destination=${destination}&sort=price,asc`;

  return await (await httpGet(url)).json();
};

export function OfferCard({ data, searchSource, isOpened, type = 'card', showOrigin = false, closeDetails = () => {}}) {
  const [isOfferDialogOpen, setIsOfferDialogOpen] = useState(false);
  const width = useWindowWidth();
  const router = useRouter();
  const [mediaHeight, setMediaHeight] = useState(0);
  const [mediaWidth, setMediaWidth] = useState(0);
  const [similarOffers, setSimilarOffers] = useState([]);
  const [offer, setOffer] = useState({...data});
  const [shareDialogState, setShareDialogState] = useState({ open: false, offer: null });
  const imageRef = createRef();
  const userStore = useSelector((state) => state.user.value);

  useEffect(() => {
    if (imageRef?.current) {
      setMediaHeight(imageRef.current.offsetWidth / 1.78);
      setMediaWidth(imageRef.current.offsetWidth);
    }
  }, [imageRef]);

  useEffect(() => {
    if (isOpened) {
      setIsOfferDialogOpen(true);
    }
  }, [isOpened]);

  useEffect(() => {
    setOffer({...data});
  }, [data]);

  const options = getTextEditorParseOptions({
    article: {},
    mediaWidth,
    mediaHeight,
    imageRef,
    styles,
  });

  const isFlightSearchLink = (offerLink) => {
    if (!offerLink) {
      return false;
    }
    return offerLink.includes('searchRedirect');
  };

  const isDealsLink = (offerLink) => {
    if (!offerLink) {
      return false;
    }
    return offerLink.includes('dealsRedirect');
  };

  const generateDealsLink = (offer) => {
    if (!offer) {
      return '';
    }
    return `/?country=${offer.country.toUpperCase()}&origin=${
      offer.origin.code
    }&destination=${offer.destination.code}&airline=${
      offer.airline.code
    }&airlineName=${offer.airline.name}&cabinClass=${
      offer.cabinClass
    }&currency=${offer.currency}`;
  };

  const closeOfferDialog = () => {
    setIsOfferDialogOpen(false);
    const query = { ...router.query };
    delete query.offerid;
    sessionStorage.setItem('BC-offerIDOpened', null);
    router.push(
      {
        pathname: router.pathname,
        query,
      },
      undefined,
      { shallow: true }
    );
    closeDetails(true);
  };

  function sendFlightOptionsClickEvent(offer, cabinClass) {
    const tagManagerArgs = {
      dataLayer: {
        event: 'offerClick',
        flightCabinClass: getCabinClass(cabinClass, true),
        userCountry: userStore?.country.toUpperCase(),
        airlineName: offer.airline.name,
        membershipId: userStore?.membershipNumber,
      },
    };
    TagManager.dataLayer(tagManagerArgs);
};

function renderOfferTableItem(offer, isOriginShown) {
  return (<a
    className={cn(styles.offerTableItem)}
    onClick={() => openOfferDetails(offer)}
  >
    <div className={styles.offerName} title={offer.destination.name}>
      {isOriginShown && (offer.origin.name + ' - ')}
      {offer.destination.name}
    </div>
    <div className={styles.offerAirlineImage}>
      {offer.airline.logo && (
        <img
          src={offer.airline.logo.replace('http', 'https')
            .replace('width=108&height=92', 'width=30&height=25') }
          title={
            offer.airline.name +
            ' (' +
            offer.airline.code +
            ')'
          }
          alt='Airline Logo'
        />
      )}

      {!offer.airline.logo && (
        <span
          title={
            offer.airline.name +
            ' (' +
            offer.airline.logo +
            ')'
          }
        >
          {offer.airline.name}
        </span>
      )}
    </div>
    <div className={styles.offerDate}>
      {moment(offer.departureDate, 'YYYY-MM-DD').format(
        'D MMM'
      )}{' '}
      -{' '}
      {moment(offer.returnDate, 'YYYY-MM-DD').format(
        'D MMM'
      )}
    </div>
    <div className={styles.offerPrice}>
      <div title={'from ' + getSymbolFromCurrency(offer.currency) + Math.round(offer.price)}>
        <span className={cn(styles.fromLabel, 'text-xs text-gray-500')}>From</span>{' '}
        <CurrencyFormat
          className={styles.price}
          thousandSeparator
          displayType={'text'}
          prefix={getSymbolFromCurrency(offer.currency) + ' '}
          value={Math.round(offer.price)}
        />
      </div>
    </div>
  </a>)
}

  function generateOffer(offer, airline) {
    if (!offer) {
      return;
    }

    return {
      from: offer.origin.code,
      to: offer.destination.code,
      dateRange: {
        departure: offer.departureDate || null,
        return: offer.returnDate || null,
      },
      cabinClass: offer.cabinClass,
      tripState: 'round_trip',
      airlineType: airline,
      passengers: { adults: 1, children: 0 },
    };
  }

  function openOfferDetails(offer) {
    setIsOfferDialogOpen(true);
    sendFlightOptionsClickEvent(offer, offer.cabinClass);
    getSimilarOffers(offer.origin.code, offer.destination.code).then((res) => {
      setSimilarOffers([...res.content]);
    });
    sessionStorage.setItem('BC-offerIDOpened', offer.id);
    router.push(
      {
        pathname: router.pathname,
        query: { ...router.query, offerid: offer.id },
      },
      undefined,
      { shallow: true }
    );
  }

  return (
    <>
      {offer && type === 'card' && (
        <div
          className={cn(styles.offerCard, 'cursor-pointer', (!offer?.destination?.region || !offer?.destination?.code) && userStore?.is_admin && 'bg-red-100')}
          onClick={() => {
            openOfferDetails(offer);
          }}
        >
        <div className={styles.offerImage}>
          <ImageWithFallback
            src={setImageUrl(offer.image)}
            width={400}
            height={400}
            objectFit="cover"
            alt={offer.destination.name}
            quality={95}
            sizes="(max-width: 1024px) 100vw, 400px"
          />
        </div>
        <div className={styles.offerData}>
          {offer.airline?.code && (
            <div className={cn(styles.offerOverline, 'flex')}>
              <p className={cn(styles.offerOverline, 'w-full m-0 p-0')}>
                <span className="font-bold">
                  {' '}
                  {getCabinClass(offer.cabinClass, false)}
                </span>
                {' | '}
                {offer.airline.name}
                <br />
                {offer.origin.name} ({offer.origin.code}) -{' '}
                {offer.destination.name} ({offer.destination.code})
                <br />
              </p>

              <div className={styles.airlineLogo}>
                <img
                  src={offer.airline.logo?.replace('http', 'https')}
                  width={40}
                  height={35}
                  loading="lazy"
                  alt="Airline logo"
                  className="ml-auto"
                />
              </div>
            </div>
          )}

          <h3 className={cn("font-fragment my-3", showOrigin && 'text-lg')}>
            {showOrigin && offer.origin.name + ' - '}
            {offer.destination.name}
          </h3>
          {(offer?.departureDate || offer?.returnDate) && (
            <span className={cn("text-xs text-black")}>
              {moment(offer?.departureDate).format(
                'D. MMM'
              )}{' '}
              - {moment(offer?.returnDate).format('D. MMM yyyy')}
            </span>
          )}

          <div className={cn(styles.offerPrice, 'font-libre')}>
            <i className="mr-1">From</i>{' '}
            <CurrencyFormat
              thousandSeparator
              displayType={'text'}
              prefix={getSymbolFromCurrency(offer.currency) + ' '}
              value={Math.round(offer.price)}
              className="mr-2"
            />
            {offer.regularPrice && (
              <>
                <span className={cn(styles.regularPrice, 'line-through mr-2')}>
                  <CurrencyFormat
                    thousandSeparator
                    displayType={'text'}
                    prefix={getSymbolFromCurrency(offer.currency) + ' '}
                    value={offer.regularPrice}
                  />
                </span>{' '}
                <span className={styles.regularPrice}>
                  (
                  {Math.round(
                    ((offer.regularPrice - offer.price) / offer.regularPrice) *
                      100
                  ) + '% off'}
                  )
                </span>
              </>
            )}
          </div>

          {userStore?.is_admin && (
            <div>
              {!offer?.destination?.region && (
                <span className='text-red-600 text-xs'>No region specified</span>
              )}

              {!offer?.destination?.code && (
                <span className='text-red-600 text-xs'>No destination code specified</span>
              )}
            </div>
          )}

          {/* <IconButton
                color="inherit"
                className={styles.shareIcon}
                onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                setShareDialogState({ open: true, offer });
                }}
                aria-label="close"
                sx={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginLeft: '5px',
                }}
                >
                <IosShareIcon className="m-0 lg:h-6 lg:w-6 -mt-1" />
            </IconButton> */}
        </div>
      </div>
      )}

      {offer && type === 'table' && (
        renderOfferTableItem(offer, width >= 1024 ? showOrigin : false)
      )}

      <Drawer
        open={isOfferDialogOpen}
        onClose={() => closeOfferDialog()}
        anchor={'right'}
        ModalProps={{
          disableAutoFocus: true,
          disableEnforceFocus: true,
        }}
        sx={{
          '& .MuiDrawer-paper': {
            width: '60%',
            backgroundColor: '#FAF8F4',
            [theme.breakpoints.down('md')]: {
              width: '100%',
            },
          },
        }}
      >
        {/* sx={{ '& .MuiDrawer-paper': { width: '60%', backgroundColor: '#FAF8F4' } }} */}

        {offer && (
          <div
            className={cn(
              styles.offerDialogContent,
              styles.offers,
              'p-3 lg:p-6 lg:pt-20 pt-16'
            )}
          >
            <div className="flex">
              <IconButton
                color="inherit"
                onClick={() => closeOfferDialog()}
                aria-label="close"
                className={styles.closeDialog}
              >
                <CloseIcon />
              </IconButton>

              <h3 className="text-3xl w-full font-libre">Offer details</h3>
            </div>

            <div
              className={cn(
                styles.offerDetailsContainer,
                'relative grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4'
              )}
              id='offer-details'
            >
              <div
                className={cn(
                  styles.offerDetailsImageContainer,
                  'relative max-w-full'
                )}
              >
                <ImageWithFallback
                  src={setImageUrl(offer?.image)}
                  layout="fill"
                  objectFit="cover"
                  alt={offer?.destination.name}
                  quality={95}
                  sizes="(max-width: 1024px) 100vw, 400px"
                  className="h-40"
                />
              </div>

              <div className={cn(styles.offerData, 'w-full')}>
                {offer.airline?.code && (
                  <div className={cn(styles.offerOverline, 'flex')}>
                    <p
                      className={cn(
                        styles.offerOverline,
                        'w-full m-0 p-0 text-base'
                      )}
                    >
                      <span className="font-bold">
                        {' '}
                        {getCabinClass(offer.cabinClass, false)}
                      </span>
                      {' | '}
                      {offer.airline.name}
                      <br />
                      {offer.origin.name} ({offer.origin.code}) -{' '}
                      {offer.destination.name} ({offer.destination.code})
                    </p>

                    <div>
                      <img
                        src={offer.airline.logo?.replace('http', 'https')}
                        width={50}
                        height={46}
                        loading="lazy"
                        alt="Airline logo"
                        className="ml-auto"
                      />
                    </div>
                  </div>
                )}
                <h3 className={cn("font-fragment text-left my-5", showOrigin ? 'text-2xl' : 'text-3xl')}>
                  {showOrigin && offer.origin.name + ' - '}{offer?.destination.name}
                </h3>

                <div
                  className={cn(
                    styles.offerPrice,
                    'text-2xl',
                    !offer.regularPrice && 'mb-4'
                  )}
                >
                  <i className="mr-2 text-base">From</i>{' '}
                  <CurrencyFormat
                    thousandSeparator
                    displayType={'text'}
                    prefix={getSymbolFromCurrency(offer?.currency) + ' '}
                    value={Math.round(offer?.price)}
                    className="mr-2"
                  />
                </div>

                {offer.regularPrice && (
                  <div className="text-base mb-4">
                    <span
                      className={cn(
                        styles.regularPrice,
                        'line-through mr-2 text-lg'
                      )}
                    >
                      <CurrencyFormat
                        thousandSeparator
                        displayType={'text'}
                        prefix={getSymbolFromCurrency(offer.currency) + ' '}
                        value={offer.regularPrice}
                      />
                    </span>{' '}
                    <span className={cn(styles.regularPrice, 'text-lg')}>
                      (
                      {Math.round(
                        ((offer.regularPrice - offer.price) /
                          offer.regularPrice) *
                          100
                      ) + '% off'}
                      )
                    </span>
                  </div>
                )}

                {!isFlightSearchLink(offer?.link) && (
                  <a
                    href={isDealsLink ? generateDealsLink(offer) : offer?.link}
                    className={cn(styles.offerLink, 'mb-2')}
                    target="_blank"
                  >
                    View offer
                  </a>
                )}

                <div className={cn(styles.offerOverline, 'mb-2')}>
                  <p className="text-lg">
                    Offer expires:{' '}
                    <b>{moment(offer?.expirationDate).format('DD.MM.YYYY')}</b>
                  </p>
                  {(offer?.departureDate || offer?.returnDate) && (
                    <p className="text-lg">
                      Travel dates:{' '}
                      <b>
                        {moment(offer?.departureDate).format('DD.MM.YYYY')} -{' '}
                        {moment(offer?.returnDate).format('DD.MM.YYYY')}
                      </b>
                    </p>
                  )}
                </div>
                
              </div>

              <Tooltip title="Share offer" placement="top">
                <IconButton
                    color="inherit"
                    className={styles.shareIcon}
                    onClick={(event) => {
                    event.stopPropagation();
                    event.preventDefault();
                    setShareDialogState({ open: true, offer });
                    }}
                    aria-label="close"
                    sx={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginLeft: '5px',
                    }}
                    >
                    <IosShareIcon className="m-0 lg:h-6 lg:w-6 -mt-1" />
                </IconButton>
              </Tooltip>
            </div>

            {isFlightSearchLink(offer?.link) && (
              <div className={cn(styles.offerDetailsContainer, 'mb-4')}>
                <h4 className="text-left font-libre mb-0">
                  <TravelExploreOutlinedIcon className="mr-2" /> Start offer
                  flight search
                </h4>
                {/* <p>
                  Select the dates and the number of passengers to start your
                  flight search
                </p> */}

                <InView triggerOnce>
                  {({ inView, ref }) => (
                    <div ref={ref} className="w-full">
                      {inView && (
                        <FlightSearch
                          collapsed={false}
                          disablePortal={false}
                          airlineName={offer.airline?.name}
                          airlineCode={offer.airline?.code}
                          airlineClass={
                            CABIN_CLASSES.find(
                              (cabin) => cabin.value === offer.cabinClass
                            ).label
                          }
                          flightSearchValueProp={generateOffer(
                            offer,
                            offer.airline?.code
                          )}
                          minDate={offer?.departureDate}
                          maxDate={offer?.returnDate}
                          defaultSortBy={'price'}
                          viewMode={width > 1600 ? 'mainSearch' : 'sideSearch'}
                          customHeader={
                            <span className="text-sm font-semibold font-libre">
                              Select your dates{' '}
                              {offer.departureDate &&
                                offer.returnDate &&
                                ` between ${moment(offer?.departureDate).format(
                                  'DD.MM.YYYY'
                                )} and ${moment(offer?.returnDate).format(
                                  'DD.MM.YYYY'
                                )}`}{' '}
                              and click &apos;Find offer&apos; to find flight
                              details
                            </span>
                          }
                          customSearchText={'Find offer'}
                          openInNewTab={true}
                          searchSource={searchSource}
                        />
                      )}
                    </div>
                  )}
                </InView>
              </div>
            )}

            {similarOffers?.length > 1 && (
              <div className={cn('mb-4')}>
                <h4 className="text-left font-libre mb-0">
                  <ListAltOutlinedIcon className="mr-2" /> Similar offers
                </h4>
                <div
                  className={cn(
                    'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-4'
                  )}
                >
                  {similarOffers.map((similarOffer) => (
                    similarOffer.id !== offer.id && (
                      <div
                        key={similarOffer.id}
                        className={cn(
                          styles.offerData,
                          styles.offerDetailsContainer,
                          'w-full cursor-pointer'
                        )}
                        onClick={() => {
                          const drawerContainer = document.querySelector('.MuiDrawer-paper');
                          if (drawerContainer) {
                            drawerContainer.scrollTo({
                              top: 0,
                              behavior: 'smooth',
                            });
                          }
                          setOffer(null);
                          setOffer(similarOffer);
                         
                          router.push(
                            {
                              pathname: router.pathname,
                              query: { ...router.query, offerid: similarOffer.id },
                            },
                            undefined,
                            { shallow: true }
                          );
                        }}
                      >
                        <div className={cn(styles.offerOverline, 'flex')}>
                          <span className="font-bold block">
                            {getCabinClass(similarOffer.cabinClass, true)}
                          </span>
                          <span className="px-1">|</span>
                          <span> {similarOffer.airline.name}</span>

                          <img
                            src={similarOffer.airline.logo?.replace('http', 'https')}
                            width={25}
                            height={20}
                            loading="lazy"
                            alt="Airline logo"
                            className="ml-auto"
                          />
                        </div>
                        {(similarOffer?.departureDate || similarOffer?.returnDate) && (
                          <div className={styles.offerOverline}>
                            <p className="text-sm">
                              <b>
                                {moment(similarOffer?.departureDate).format(
                                  'DD.MM.YYYY'
                                )}{' '}
                                - {moment(similarOffer?.returnDate).format('DD.MM.YYYY')}
                              </b>
                            </p>
                          </div>
                        )}
                        <div
                          className={cn(
                            styles.offerPrice,
                            'text-xl mt-4'
                          )}
                        >
                          <i className="mr-2 text-base">From</i>{' '}
                          <CurrencyFormat
                            thousandSeparator
                            displayType={'text'}
                            prefix={getSymbolFromCurrency(similarOffer?.currency) + ' '}
                            value={Math.round(similarOffer?.price)}
                            className="mr-2"
                          />
                        </div>

                        {similarOffer.regularPrice && (
                          <div className="text-base">
                            <span
                              className={cn(
                                styles.regularPrice,
                                'line-through mr-2 text-base'
                              )}
                            >
                              <CurrencyFormat
                                thousandSeparator
                                displayType={'text'}
                                prefix={
                                  getSymbolFromCurrency(similarOffer.currency) + ' '
                                }
                                value={similarOffer.regularPrice}
                              />
                            </span>{' '}
                            <span className={cn(styles.regularPrice, 'text-lg')}>
                              (
                              {Math.round(
                                ((similarOffer.regularPrice - similarOffer.price) /
                                similarOffer.regularPrice) *
                                  100
                              ) + '% off'}
                              )
                            </span>
                          </div>
                        )}
                      </div>
                    )
                  ))}
                </div>
              </div>
            )}

            <div className={cn(styles.offerDetailsContainer, 'mb-4')}>
              <h4 className="text-left font-libre">
                <DescriptionOutlinedIcon className="mr-2" /> Offer description,
                terms and conditions
              </h4>
              <p>{parse(offer.content, options)}</p>
            </div>

            <div className={styles.offerDetailsContainer}>
              <h4 className="text-left font-libre">
                <AnnouncementOutlinedIcon className="mr-2" /> Important notes
              </h4>
              <p>
                Amazing fares like these don’t last long - they can disappear
                within hours, or even minutes! If you don’t see similar prices
                to those listed above, try selecting different travel dates to
                find other great options. And don’t worry, we’ve got plenty more
                incredible deals coming your way very soon. Stay tuned!
              </p>
            </div>
          </div>
        )}
      </Drawer>

      <Dialog
        open={shareDialogState.open && shareDialogState.offer}
        onBackdropClick={() => {
          setShareDialogState({ open: false, offer: null });
        }}
        PaperProps={{
          style: { position: 'relative', minWidth: '30vw' },
        }}
      >
        <IconButton
          color="inherit"
          onClick={() => {
            setShareDialogState({ open: false, offer: null });
          }}
          aria-label="close"
          className={styles.closeShareDialog}
        >
          <CloseIcon />
        </IconButton>

        <div className="relative">
          <div className={styles.imageHeader}>
            {shareDialogState.offer && (
              <ImageWithFallback
                src={
                  setImageUrl(shareDialogState.offer.image)
                }
                width={1200}
                height={400}
                quality={50}
                objectFit="cover"
              />
            )}
          </div>

          {shareDialogState.offer && (
            <h3
              className={cn(
                styles.imageTitle,
                'font-fragment font-normal text-xl lg:text-3xl'
              )}
            >
              {shareDialogState.offer.origin.name} - {
                shareDialogState.offer.destination.name}
            </h3>
          )}
        </div>

        <div className="p-4 mt-3">
          <h4 className='font-libre'>Share this offer</h4>

          <div className="flex align-items-center">
            <TextField
              label="Share url"
              variant="outlined"
              defaultValue={`${process.env.apiUrl}${router.asPath}`}
              className="w-full"
              inputProps={{ readOnly: true }}
            />
            <Button
              variant="contained"
              color="secondary"
              sx={{
                backgroundColor: '#0a0a0a',
                color: '#ffffff',
                marginLeft: '1rem',
                textTransform: 'none',
                minWidth: '100px',

                '&:hover': {
                  backgroundColor: '#ffffff',
                  color: '#0a0a0a',
                },
              }}
              onClick={() => {
                navigator.clipboard.writeText(
                  `${process.env.apiUrl}${router.asPath}`
                );
                setShareDialogState({ open: false, offer: null });
              }}
            >
              Copy link
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
}
